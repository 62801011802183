import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { Post } from '@components/Post';
import { statusPost1, statusPost2, statusPost3, statusPost4, stadiumPost1, stadiumPost2, fixturePost1, fixturePost2 } from './data/posts.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "post"
    }}>{`Post`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Post} mdxType="Props" />
    <h2 {...{
      "id": "status-post"
    }}>{`Status Post`}</h2>
    <Playground __position={1} __code={'<Post post={statusPost1} />\n<Post post={statusPost2} />\n<Post post={statusPost3} />\n<Post post={statusPost4} />\n<Post post={stadiumPost1} />\n<Post post={stadiumPost2} />\n<Post post={fixturePost1} />\n<Post\n  post={fixturePost2}\n  onPressUser={() => console.log(\'press user\')}\n  onPressVenue={() => console.log(\'press venue\')}\n  onPressOptions={() => console.log(\'press options\')}\n  onPressFixture={() => console.log(\'press fixture\')}\n  onPressReaction={() => console.log(\'press reaction\')}\n  onPressReactionCount={() => console.log(\'press reaction count\')}\n  onPressComments={() => console.log(\'press comments\')}\n  onPressGallery={() => console.log(\'press gallery\')}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Post,
      statusPost1,
      statusPost2,
      statusPost3,
      statusPost4,
      stadiumPost1,
      stadiumPost2,
      fixturePost1,
      fixturePost2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Post post={statusPost1} mdxType="Post" />
  <Post post={statusPost2} mdxType="Post" />
  <Post post={statusPost3} mdxType="Post" />
  <Post post={statusPost4} mdxType="Post" />
  <Post post={stadiumPost1} mdxType="Post" />
  <Post post={stadiumPost2} mdxType="Post" />
  <Post post={fixturePost1} mdxType="Post" />
  <Post post={fixturePost2} onPressUser={() => console.log('press user')} onPressVenue={() => console.log('press venue')} onPressOptions={() => console.log('press options')} onPressFixture={() => console.log('press fixture')} onPressReaction={() => console.log('press reaction')} onPressReactionCount={() => console.log('press reaction count')} onPressComments={() => console.log('press comments')} onPressGallery={() => console.log('press gallery')} mdxType="Post" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      